html,
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: calc(14px + 0.390625vw);
}

body {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  width: 100%;
  font-family: Nunito !important;
}

aside,
header,
footer,
article {
  display: block;
}

/* Typography */
h1 {
  font-size: 2.875em;
}

h2 {
  font-size: 2.625rem;
}

h3 {
  font-size: 2.375rem;
}

h4 {
  font-size: 2.125rem;
}

h5 {
  font-size: 1.875rem;
}
/* p {
  font-size: 1.5rem;
} */

/* ====== UTILITIES =======*/

/* ===== Flex Utilities ===== */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.a-center {
  align-items: center;
}

.a-left {
  align-items: flex-start;
}

.j-align {
  justify-content: center;
}
.j-btw {
  justify-content: space-between;
}

.home-fam {
  font-family: Nunito !important;
  /* background: #f6f6f9; */
  font-size: 16px;
}

/* ======= Text Utilities */
.t-center {
  text-align: center;
}
.t-mid {
  font-size: 1.25rem;
}
.t-sm {
  font-size: 1rem;
}

.w-100 {
  width: 100%;
}

.bold {
  font-weight: bold;
}
.preload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: #fff;

  /* animation:preload-animation 4s 1; */
  /* -webkit-animation:preload-animation 4s 1; */
  /* animation-fill-mode: forwards; */

  /* animation-delay:2s; */
  /* -webkit-animation-delay:2s; Safari and Chrome */
  /* -webkit-animation-fill-mode: forwards; */
}

/*
===================
  Utility Class
===================
*/

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.j-btw {
  justify-content: space-between;
}

.a-center {
  align-items: center;
}

.t-center {
  text-align: center;
}

.icon {
  font-size: 1.7rem;
}

.line {
  color: #070707;
  background-color: #070707;
  height: 3px;
  width: 100%;
}

.preload2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@keyframes preload-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes preload-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Google translation powered by google */

/* .VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none;
} */

/* Remove Google translate on top */

/* .VIpgJd-ZVi9od-ORHb {
  display: none;
  border: 2px solid red;
}

.skiptranslate .goog-te-gadget {
  color: red;
} */

/* .goog-te-combo,
.VIpgJd-ZVi9od-ORHb *,
.VIpgJd-ZVi9od-SmfZ *,
.VIpgJd-ZVi9od-xl07Ob *,
.VIpgJd-ZVi9od-vH1Gmf *,
.VIpgJd-ZVi9od-l9xktf * {
  display: none !important;
} */
