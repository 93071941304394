@media (max-width: 700px) {
    .header-text{
        font-size: medium !important;
    }

}
@media (max-width: 400px) {
    .content{
        padding: 40px 10px 40px 10px !important;
    }
}
