@font-face {
  font-family: Bahuraksa;
  src: url(./Bahuraksa.otf);
}
@font-face {
  font-family: circous;
  src: url(./TheCircous.otf);
}
@font-face {
  font-family: circous-striped;
  src: url(./TheCircousStriped.otf);
}
@font-face {
  font-family: aloja-light;
  src: url(./Aloja-Light.otf);
}
@font-face {
  font-family: aventura;
  src: url(./AventuraFF/Aventura-Bold.otf);
}
@font-face {
  font-family: banthers;
  src: url(./BanthersFF/Swistblnk\ Banthers.otf);
}
@font-face {
  font-family: barbaro;
  src: url(./BarbaroFF/barbaro.ttf);
}
@font-face {
  font-family: christamstime;
  src: url(./ChristamstimeFF/ChristmasTime-Display.otf);
}
@font-face {
  font-family: chubby;
  src: url(./ChubbyFF/Chubby.ttf);
}
@font-face {
  font-family: curely;
  src: url(./CurelyFF/Curely-Free\ Typeface.otf);
}
@font-face {
  font-family: dpopper;
  src: url(./DpopperFF/dPopper.ttf);
}
@font-face {
  font-family: dreamers-brush;
  src: url(./DreamersBrushFF/DREAMERS\ BRUSH.otf);
}
@font-face {
  font-family: fabfelt;
  src: url(./FabfeltFF/FabfeltScript-Bold.otf);
}
@font-face {
  font-family: fontarto;
  src: url(./FonartoFF/Fonarto.otf);
}
@font-face {
  font-family: furgatorio;
  src: url(./FurgatorioFF/Furgatorio.ttf);
}
@font-face {
  font-family: furgatorio;
  src: url(./FurgatorioFF/Furgatorio.ttf);
}
@font-face {
  font-family: grothika;
  src: url(./GrothikaFF/Grothika-Regular.otf);
}
@font-face {
  font-family: ikra-slab;
  src: url(./IkraSlabFF/IkraSlab.ttf);
}
@font-face {
  font-family: jakes-fuzzy-bold;
  src: url(./JakesFuzzyFeltFF/JakesFuzzyFelt-Bold.otf);
}
@font-face {
  font-family: jakes-fuzzy-light;
  src: url(./JakesFuzzyFeltFF/JakesFuzzyFelt-Light.otf);
}
@font-face {
  font-family: kaguru;
  src: url(./KaguraFF/Kagura.ttf);
}
@font-face {
  font-family: kohta;
  src: url(./KohtaFF/Kohta.ttf);
}
@font-face {
  font-family: kontainer;
  src: url(./KontainerFF/Kontanter-Bold.otf);
}
@font-face {
  font-family: kontainer;
  src: url(./KontainerFF/Kontanter-Bold.otf);
}
@font-face {
  font-family: mechano;
  src: url(./MechanoFF/MECHANO.ttf);
}
@font-face {
  font-family: melma;
  src: url(./MelmaFF/Melma.ttf);
}
@font-face {
  font-family: mom;
  src: url(./MomFF/Mom-Outline.ttf);
}
@font-face {
  font-family: pelemeshka;
  src: url(./PelemeshkaFF/Pelmeshka.ttf);
}
@font-face {
  font-family: plume;
  src: url(./PlumeFF/Plume-Free-Brush-Font.otf);
}
@font-face {
  font-family: repo-pocket;
  src: url(./RepoPocketFF/Repo\ Pocket\ Condensed.otf);
}
@font-face {
  font-family: repo-pocket-serif;
  src: url(./RepoPocketSerifFF/Repo\ Pocket\ Slab\ Serif.otf);
}
@font-face {
  font-family: scratch;
  src: url(./ScratchFF/Scratch-Year\ 1.otf);
}
@font-face {
  font-family: scritch;
  src: url(./ScritchFF/Scritch.otf);
}
@font-face {
  font-family: signify;
  src: url(./SignifyFF/fonts/signify.ttf);
}
@font-face {
  font-family: tostada;
  src: url(./TostadaFF/TOSTADA.ttf);
}
@font-face {
  font-family: variane;
  src: url(./VarianeScriptFF/Variane\ Script.ttf);
}
@font-face {
  font-family: Roboto sarif;
  src: url(./RobotoSerif-Black.otf);
}
/* @font-face {
    font-family: Roboto;
    src: url(./Roboto-Black.ttf);
  } */
