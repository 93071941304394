.TextField-without-border-radius fieldset {
  border-radius: 15px;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none
}

.rbc-month-view {
  display: block;
}

.nav-icon {
  cursor: pointer;
  transition: transform .4s ease-in-out;
}

.nav-icon:hover {
  transform: scale(1.5);
}

.rbc-active {
  background: #1a8fff !important;
  color: #fff !important;
}

.filtering .rbc-time-view,
.filtering .rbc-month-view {
  opacity: 0.4;
}

.rect-skeleton {
  height: 18rem !important;
}

@media (max-width: 700px) {
  .title-reponsive {
    font-size: 20px !important;
  }

  .sub-reponsive {
    font-size: 18px !important;
  }

  .rect-skeleton {
    height: 12rem !important;
  }

  .slider-image {
    height: 12rem !important;
  }
}

@media (min-width: 500px) {
  .rbc-toolbar {
    flex-wrap: nowrap;
  }
}