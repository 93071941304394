.react-datepicker-year-header {
  background: #2667b1 !important;
  color: #fff !important;
}
.input_content {
  width: 100%;
  height: 100%;
}
.select-date-picker {
  padding-left: 20px;
  font-size: 20px;
  text-align: left;
  width: 100%;
  height: 45px;
  outline: none;
  border: 2px solid #2667b1;
  border-radius: 12px;
  margin-bottom: 10px;
}
.drop-zone {
  width: 100%;
  min-height: 12rem;
  border: 2px dashed grey;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drop-text {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: grey;
}

.error-msg,
.error-msg-image {
  color: #f83f23;
  font-weight: 400;
  font-size: 0.76rem;
  line-height: 20px;
  width: 100%;
  display: block;
  text-align: left;
  margin-top: -5px;
}

.error-msg-image {
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
}
.cover-image {
  min-height: 10rem;
  max-height: 18rem;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 1000px) {
  .create-head {
    justify-content: center !important;
  }
}
