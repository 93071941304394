/* These styles are used if a demo specific stylesheet is not present */

/* *,
*:before,
*:after {
  box-sizing: border-box;
} */

/* form {
  max-width: 800px;
  margin: 20px auto;
}

label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  margin-top: 16px;
  display: block;
} */

/* input,
select {
  display: block;
  border: none;
  font-size: 18px;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  width: 500px;
}

input::placeholder {
  color: #aab7c4;
} */

.result,
.error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.error {
  color: #e4584c;
}

.result {
  color: #666ee8;
}

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  display: block;
  margin: 10px 0 0px 0;
  /* max-width: 500px; */
  width: 100%;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementP24--focus,
.StripeElementEPS--focus,
.StripeElementFpx--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  padding: 0;
}

/* Custom */
.card_collect {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}

/* Summary */
.payment_summary_container {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.payment_summary_container h2 {
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 20px;
  color: #0a0a0a;
}

.payment_summary_container .summary {
  display: flex;
  gap: 1.5rem;
}

.payment_summary_container .summary_item {
  display: flex;
  gap: 1rem;
}

.payment_summary_container .summary_item span {
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 27px;
  text-align: center;
  color: #0a0a0a;
}

.payment_summary_container .total_container {
  display: flex;
  gap: 1rem;
}

.payment_summary_container .total_container span {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 27px;
  text-align: center;
  color: #43abf9;
}
