*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main{
    color: white;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 3rem;
}
.sub-container {
    width: 100%;
    color: white;
    display: block;
}

.centered-content{
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 900;
}

.fashion {
    font-size: 30px;
    letter-spacing: 5px;
}

.centered-content h1{
    font-size: 100px;
    letter-spacing: -10px;
}

.newyear{
    text-transform: uppercase;
}

.center-a{
    display: flex;
    justify-content: center;
}

.a {
    border-radius: 50%;
    width: max-content;
    background-color: green;
    padding: 20px;
}

.flex-details,
.sm-icons{
    display: flex;
    padding-left: 40px;
    align-items: center;
}

.flex-details {
    margin-bottom: 20px;
}

.flex-details img,
.sm-icons div {
    margin-right: 20px;
}

.white {
    background-color: white;
    border-radius: 50%;
    width: 40px;
    padding: 10px;
}

.final{
    margin-top: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.btn {
    background: #3498db;
    background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
    background-image: -moz-linear-gradient(top, #3498db, #2980b9);
    background-image: -ms-linear-gradient(top, #3498db, #2980b9);
    background-image: -o-linear-gradient(top, #3498db, #2980b9);
    background-image: linear-gradient(to bottom, #3498db, #2980b9);
    -webkit-border-radius: 28;
    -moz-border-radius: 28;
    border-radius: 28px;
    font-family: Arial;
    color: #ffffff;
    font-size: 20px;
    padding: 10px 40px 10px 40px;
    text-decoration: none;
  }
  
  .btn:hover {
    background: #3cb0fd;
    background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
    background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
    text-decoration: none;
  }