.bespoke-con {
  background: #fff;
}
/* purgecss start ignore */
@tailwind base;
@tailwind components;
@import url('https://use.typekit.net/mpa1wkh.css');
html,
body {
  font-family: 'acumin-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  color: rgb(75, 75, 75);
  /* background: #e0e0e0; */
}
/* purgecss end ignore */

@tailwind utilities;

.component-selected {
  @apply relative;
}
.component-selected::after {
  content: ' ';
  @apply border-primary border border-dashed w-full h-full absolute left-0 top-0 pointer-events-none block;
}

.transition {
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

#carbonads * {
  margin: initial;
  padding: initial;
}

#carbonads {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;

  padding: 10px 0.5rem;
  border-top: 1px solid rgb(0 0 0 / 6%);
}
.PhoneInputInput {
  outline: none !important;
}
.notification-sub {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  max-height: 80px;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
}
.notification-sub:after {
  content: '...';
  display: inline-block;
}
