.top-template {
  display: flex;
  height: 60vh;
}

.top-left {
  overflow-y: scroll;
  width: 60%;
}

.top-right {
  width: 40%;
  padding: 40px;
  margin: 0 auto;
}

@media screen and (max-width: 750px) {
  .top-template {
    flex-direction: column;
    height: auto;
  }
  .top-left {
    width: 100%;
  }
  .top-right {
    width: 100%;
  }
  .template_list_container {
    overflow-x: scroll;
  }
}

/* 
.top-right button {
    background: #3498db;
    background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
    background-image: -moz-linear-gradient(top, #3498db, #2980b9);
    background-image: -ms-linear-gradient(top, #3498db, #2980b9);
    background-image: -o-linear-gradient(top, #3498db, #2980b9);
    background-image: linear-gradient(to bottom, #3498db, #2980b9);
    -webkit-border-radius: 28;
    -moz-border-radius: 28;
    border-radius: 28px;
    font-family: Arial;
    color: #ffffff;
    font-size: 20px;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
}
  
.top-right button:hover {
    background: #3cb0fd;
    background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
    background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
    text-decoration: none;
} */
