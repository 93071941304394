/* FONT IMPORTS */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

/* body {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
  position: relative;
} */

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar Cover */
.month-cover {
  width: 100%;
  /* height: 400px; */
  height: auto;

  /* margin-top: 3rem; */
}

.month-cover img {
  width: 100%;
  height: auto;
}
.month-cover-wiget {
  width: 100%;
  height: 400px;
  /* margin-top: 3rem; */
}
.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}
/* Calendar content */
.calendar-content {
  padding: 18px 35px 18px 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav-con {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background: red; */
}

.navigator {
  padding: 1.75em 0;
  display: flex;
  align-items: center;
}

.event-card {
  width: 50%;
  padding: 0px 18px;
}

.calender-event {
  /* margin-top: 84px; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navigator-title {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #000000;
  padding-left: 10px;
  padding-right: 10px;
}

.event-img {
  width: 262px;
  height: 240px;
}

.calendar-content .date {
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: Nunito;
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.event-dot {
  margin-top: 15px;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  background: #d9d9d9;
  border-radius: 50%;
}

.event-details {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}
.event-schedule-con {
  width: 100%;
}
.event-schedule p {
  margin-top: 0px;
  margin-bottom: 2px;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.event-schedule h6 {
  margin-top: 0px;
  margin-bottom: 2px;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.tag {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  max-height: 80px;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
}
.tag:after {
  content: '...';
  display: inline-block;
}
.join-btn {
  width: 197px;
  height: 40px;
  background: #78ddfb;
  border-radius: 8px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #000000;
  cursor: pointer;
}

.btn-con {
  padding: 20px 0px;
}

.join-btn {
  width: 197px;
  height: 40px;
  background: #78ddfb;
  border-radius: 8px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #000000;
  cursor: pointer;
}

.add-btn {
  margin-top: 10px;
  width: 197px;
  height: 40px;
  border: 4px solid #fb7878;
  border-radius: 8px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  cursor: pointer;
}

.calendar-btn {
  display: none;
  cursor: pointer;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 50%;
  background: var(--neutral-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
}

.calendar .body .cell {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 5em;
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell .inner-cell {
  border-radius: 50%;
  padding: 10px;
  min-width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.calendar .body .cell .inner-cell.select-date {
  background: #78ddfb;
}

.calendar .body .cell .inner-cell .dots {
  display: flex;
  flex-direction: row;
}

.calendar .body .cell .inner-cell .dot {
  margin-left: 1px;
  margin-right: 1px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.calendar .body .cell .inner-cell:hover {
  border: 2px solid #78ddfb;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  /* border-bottom: 1px solid var(--border-color); */
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  font-size: 82.5%;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}
.hidden {
  display: none !important;
}

.location {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.note .note_desc {
  max-height: 260px;
  overflow-y: scroll;
}

/* Media Queries */
@media (max-width: 1024px) {
  .month-cover {
    height: auto;
  }
  .month-cover-wiget {
    height: 260px;
  }
  .mobile-calendar {
    display: none !important;
  }

  .calendar {
    width: 100% !important;
  }

  .calendar-content {
    padding: 18px 0px;
  }

  .col-center {
    font-size: 2.5vw;
  }

  .calender-event {
    margin-top: 0px;
    width: 100%;
    padding: 0px 8px;
  }

  .calendar-content .date {
    font-size: 18px;
  }

  .event-schedule p {
    font-size: 14px;
  }

  .event-schedule h6 {
    font-size: 14px;
  }

  .tag {
    font-size: 14px;
  }

  .join-btn {
    width: 140px;
    height: 40px;
    font-size: 14px;
  }

  .add-btn {
    width: 140px;
    height: 40px;
    font-size: 14px;
  }

  .event-img-con {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .event-img {
    width: 90%;
    height: 130px;
    /* height: 18vh; */
  }

  .nav-con {
    margin-bottom: 18px;
  }

  .calendar-btn {
    display: block;
    margin-top: 64px;
    width: 140px;
    height: 40px;
    border: 4px solid #43abf9;
    border-radius: 8px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #000000;
  }

  .event-card {
    width: 100%;
  }
  .event-content {
    width: 50%;
  }
  .note {
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .month-cover-fourth {
    width: 95%;
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
  }
  .month-cover {
    width: 90%;
    height: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
  }
  .month-cover-wiget {
    width: 90%;
    height: 400px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
  }
  .nav-hidden {
    padding: 3.5em 0;
  }
  .event-content {
    width: 50%;
  }
}
@media (min-width: 280px) and (max-width: 320px) {
  .calendar-content .date {
    font-size: 14px;
  }

  .event-schedule p {
    font-size: 12px;
  }

  .event-schedule h6 {
    font-size: 12px;
  }

  .tag {
    font-size: 12px;
  }

  .join-btn {
    width: 100px;
    height: 35px;
    font-size: 12px;
  }

  .add-btn {
    width: 100px;
    height: 40px;
    font-size: 12px;
  }

  .event-img-con {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .event-img {
    width: 90%;
    height: 12vh;
  }

  .nav-con {
    margin-bottom: 18px;
  }

  .calendar-btn {
    width: 100px;
    height: 40px;
    font-size: 12px;
  }
  .event-content {
    width: 50%;
  }
}

@media (min-width: 760px) and (max-width: 1024px) {
  .calender-event {
    padding: 0px 100px;
  }
  .note {
    width: 70%;
  }
}
